import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_TIME_ZONE } from '../../../common/resources/src/global-variables';
import { BlockingAppointmentV2 } from '../../types/src/appointmentV2';
import { AppointmentV2Type } from '../../types/src/appointmentV2Type';
import { ChatUser } from '../../types/src/chatUser';
import { AppointmentClosingDayUtil } from './appointment-closing-day.util';
import { AppointmentTimeslotUtil } from './appointment-timeslot.util';

dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone); // dependent on utc plugin

export interface PharmacyOpeningData {
  openingHours: string[];
  pharmacyChatUser: ChatUser;
}

export class AppointmentAvailabilityUtil {
  public static getAvailableDates({
    selectedAppointmentType,
    pharmacy,
    blockingAppointments,
  }: {
    selectedAppointmentType: AppointmentV2Type;
    pharmacy: PharmacyOpeningData;
    blockingAppointments: BlockingAppointmentV2[];
  }): Dayjs[] {
    let selectedDate =
      selectedAppointmentType.startDate?.add(selectedAppointmentType.leadTimeInHours, 'hour') ||
      dayjs().tz(DEFAULT_TIME_ZONE).add(selectedAppointmentType.leadTimeInHours, 'hour');

    const availableDates: Dayjs[] = [];
    while (availableDates.length < 14) {
      if (
        selectedDate.isAfter(selectedAppointmentType.endDate) ||
        selectedDate.isAfter(dayjs().tz(DEFAULT_TIME_ZONE).add(30, 'days'))
      ) {
        break;
      }
      if (
        this.hasAvailableSlots({
          pharmacy,
          selectedAppointmentType,
          blockingAppointments,
          selectedDate,
        }) &&
        !AppointmentClosingDayUtil.isClosed(selectedDate, pharmacy.pharmacyChatUser)
      ) {
        availableDates.push(selectedDate);
      }
      selectedDate = selectedDate.add(1, 'day');
    }
    return availableDates;
  }

  public static getAvailableTimes({
    pharmacy,
    selectedAppointmentType,
    blockingAppointments,
    selectedDate,
  }: {
    pharmacy: PharmacyOpeningData;
    selectedAppointmentType: AppointmentV2Type;
    blockingAppointments: BlockingAppointmentV2[];
    selectedDate?: Dayjs;
  }): string[] | undefined {
    if (!selectedDate) {
      return undefined;
    }
    if (!selectedAppointmentType.timeslots[AppointmentTimeslotUtil.getArrayIndexFromWeekday(selectedDate)]) {
      return undefined;
    }
    const openingHours = pharmacy.openingHours[AppointmentTimeslotUtil.getArrayIndexFromWeekday(selectedDate)];
    const blockingAppointmentsOnDay = blockingAppointments.filter((appointment) =>
      appointment.date.isSame(selectedDate, 'day')
    );
    const availableTimeslots = AppointmentTimeslotUtil.determineAvailableTimeslots({
      date: selectedDate,
      blockingAppointmentsOnDay,
      openingHours,
      selectedAppointmentType,
    });
    return availableTimeslots
      .map((timeslot) =>
        AppointmentTimeslotUtil.getAvailableTimeslotTimesForEnduser(timeslot, selectedDate, selectedAppointmentType)
      )
      .flat();
  }

  private static hasAvailableSlots({
    pharmacy,
    selectedAppointmentType,
    blockingAppointments,
    selectedDate,
  }: {
    pharmacy: PharmacyOpeningData;
    selectedAppointmentType: AppointmentV2Type;
    blockingAppointments: BlockingAppointmentV2[];
    selectedDate: Dayjs;
  }): boolean {
    if (!selectedAppointmentType.timeslots[AppointmentTimeslotUtil.getArrayIndexFromWeekday(selectedDate)]) {
      return false;
    }
    const availableTimeslots = this.getAvailableTimes({
      selectedAppointmentType,
      pharmacy,
      blockingAppointments,
      selectedDate,
    });
    return !!availableTimeslots?.length;
  }
}
