import { createSelector, select } from '@ngrx/store';
import { Dayjs } from 'dayjs';
import { Observable, pipe, UnaryFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dictionary } from 'ts-essentials';
import { DecryptedAppointmentV2 } from '../../../../essentials/types/src/appointmentV2';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import { AppointmentUtil } from '../../../../essentials/util/src/appointment.util';
import { CommonState } from '../../common-store/common.state';
import { AppointmentV2State } from './appointmentV2.state';

const selectAppointmentV2State = (state: CommonState & { appointmentV2: AppointmentV2State }) => state.appointmentV2;

export const selectAppointmentV2TypesLoadStatus = createSelector(
  selectAppointmentV2State,
  (state: AppointmentV2State) => state.appointmentV2TypesLoadStatus
);

export const selectAppointmentV2Types = createSelector(selectAppointmentV2State, (state: AppointmentV2State) =>
  Object.values(state.appointmentV2Types)
);

export const selectAppointmentsV2LoadStatusDictionary = createSelector(
  selectAppointmentV2State,
  (state: AppointmentV2State) => state.appointmentsV2LoadStatus
);

export const pipeUpToDateMonths: UnaryFunction<
  Observable<CommonState & { appointmentV2: AppointmentV2State }>,
  Observable<string[]>
> = pipe(
  select(selectAppointmentsV2LoadStatusDictionary),
  map((appointmentsLoadStatusDictionary) =>
    Object.entries(appointmentsLoadStatusDictionary)
      .filter(([_, loadStatus]) => loadStatus === LoadStatus.UpToDate)
      .map(([month, _]) => month)
  )
);

export const pipeLoadingOrUpToDateMonths: UnaryFunction<
  Observable<CommonState & { appointmentV2: AppointmentV2State }>,
  Observable<string[]>
> = pipe(
  select(selectAppointmentsV2LoadStatusDictionary),
  map((appointmentsLoadStatusDictionary) =>
    Object.entries(appointmentsLoadStatusDictionary)
      .filter(([_, loadStatus]) =>
        [LoadStatus.LoadingInitial, LoadStatus.Revalidating, LoadStatus.UpToDate].includes(loadStatus)
      )
      .map(([month, _]) => month)
  )
);

export const selectActiveMonths = createSelector(
  selectAppointmentV2State,
  (state: AppointmentV2State) => state.activeMonths
);

export const selectAppointmentsV2Dictionary = createSelector(
  selectAppointmentV2State,
  (state: AppointmentV2State) => state.appointmentsV2
);

export const selectAppointmentsV2 = createSelector(selectAppointmentV2State, (state: AppointmentV2State) => {
  const appointments: DecryptedAppointmentV2[] = [];
  const appointmentV2Dictionaries = Object.values(state.appointmentsV2);
  appointmentV2Dictionaries.forEach((dictionary) => appointments.push(...Object.values(dictionary)));
  return appointments;
});

export const selectMergedAppointmentsV2Dictionary = createSelector(
  selectAppointmentV2State,
  (state: AppointmentV2State) => {
    let mergedDictionary: Dictionary<DecryptedAppointmentV2> = {};
    const appointmentV2Dictionaries = Object.values(state.appointmentsV2);
    appointmentV2Dictionaries.forEach((dictionary) => (mergedDictionary = { ...mergedDictionary, ...dictionary }));
    return mergedDictionary;
  }
);

export const selectAppointmentsV2OnDay = (date: Dayjs) =>
  createSelector(selectAppointmentV2State, (state: AppointmentV2State) => {
    const startOfMonth = AppointmentUtil.getCurrentAppointmentMonth(date);
    const appointmentsInMonth = state.appointmentsV2[startOfMonth];
    if (!appointmentsInMonth) {
      return [];
    }
    return Object.values(appointmentsInMonth).filter((appointment) => appointment.date.isSame(date, 'day'));
  });
