import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppsyncErrorUtil } from '../../../../../../../libs/essentials/util/src/appsync-error.util';
import { Logger } from '../../../../../../../libs/essentials/util/src/logger';
import { updateAppointmentId } from '../../../../../../../libs/store/src/common-store/chat-store/actions/chat-conversation-update.actions';
import {
  newAppointment,
  newShoppingCartInChat,
} from '../../../../../../../libs/store/src/common-store/chat-store/actions/chat-decryption.actions';
import { newBackendMessage } from '../../../../../../../libs/store/src/common-store/chat-store/actions/chat-message.actions';
import {
  initAudioOnInteraction,
  initHomeState,
  loadConversationIdsByDay,
  loadConversationIdsByDayFailure,
  loadConversationIdsByDaySuccess,
  setSoundNotification,
  setSoundNotificationFromLocalStorage,
  setSoundRepetition,
  setSoundRepetitionFromLocalStorage,
} from '../actions/home.actions';
import { AppsyncSettingsService } from './services/appsync-settings.service';
import { AudioService } from './services/audio.service';
import { SoundNotificationService } from './services/sound-notification.service';

const logger = new Logger('HomeEffects');

@Injectable()
export class HomeEffects {
  loadConversationIdsByDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConversationIdsByDay),
      switchMap(async ({ lastDays }) => {
        try {
          const conversationIdsByDay = await this.appsyncSettingsService.fetchConversationsByDay(lastDays);
          return loadConversationIdsByDaySuccess({ conversationIdsByDay });
        } catch (err) {
          if (
            AppsyncErrorUtil.isAppsyncError(err) &&
            err.errors[0] &&
            AppsyncErrorUtil.isElasticSearchNotFoundError(err.errors[0])
          ) {
            return loadConversationIdsByDaySuccess({ conversationIdsByDay: [] });
          } else {
            return loadConversationIdsByDayFailure({ errorMessage: 'REQUEST_ERROR.CONVERSATIONS_BY_DAY' });
          }
        }
      })
    )
  );

  playSoundOnIncomingMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(newBackendMessage),
        filter(({ message }) => !message.initialMessage),
        mergeMap(async () => {
          try {
            await this.audioService.playSoundAndResetTimer();
          } catch (err) {
            logger.error('Could not play sound.', err);
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  playSoundOnNewConversationWithAttachment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(newAppointment, newShoppingCartInChat, updateAppointmentId),
        mergeMap(async () => {
          try {
            await this.audioService.playSoundAndResetTimer();
          } catch (err) {
            logger.error('Could not play sound.', err);
          }
        })
      ),
    { dispatch: false }
  );

  setSoundNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSoundNotification),
        map(({ soundNotification }) => {
          this.soundNotificationService.setSoundNotificationInLocalStorage(soundNotification);
        })
      ),
    { dispatch: false }
  );

  initSoundNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initHomeState),
      map(() => {
        const soundNotificationFromLocalStorage = this.soundNotificationService.getSoundNotificationFromLocalStorage();
        return setSoundNotificationFromLocalStorage({ soundNotification: soundNotificationFromLocalStorage });
      })
    )
  );

  setSoundRepetition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSoundRepetition),
        map(({ soundRepetition }) => {
          this.soundNotificationService.setSoundRepetitionInLocalStorage(soundRepetition);
        })
      ),
    { dispatch: false }
  );

  initSoundRepetition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initHomeState),
      map(() => {
        const soundRepetitionFromLocalStorage = this.soundNotificationService.getSoundRepetitionFromLocalStorage();
        return setSoundRepetitionFromLocalStorage({ soundRepetition: soundRepetitionFromLocalStorage });
      })
    )
  );

  initAudio$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initAudioOnInteraction),
        tap(() => {
          this.audioService.initAudioOnInteraction();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private audioService: AudioService,
    private appsyncSettingsService: AppsyncSettingsService,
    private soundNotificationService: SoundNotificationService
  ) {}
}
