import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_TIME_ZONE } from '../../../common/resources/src/global-variables';
import { BlockingAppointmentV2, DecryptedAppointmentV2 } from '../../types/src/appointmentV2';
import {
  AppointmentV2Type,
  BookedAppointmentV2Type,
  Time,
  Timeslot,
  TimeslotWithStatus,
} from '../../types/src/appointmentV2Type';

dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone); // dependent on utc plugin
dayjs.extend(isSameOrBefore);

export class AppointmentTimeslotUtil {
  private static readonly blockingTimeslot: Timeslot = { from: '00:00', until: '23:50' };

  static determineAvailableTimeslots({
    date,
    blockingAppointmentsOnDay,
    currentAppointmentId,
    openingHours,
    selectedAppointmentType,
  }: {
    date: Dayjs;
    blockingAppointmentsOnDay: DecryptedAppointmentV2[] | BlockingAppointmentV2[];
    currentAppointmentId?: string;
    openingHours?: string;
    selectedAppointmentType?: AppointmentV2Type;
  }): Timeslot[] {
    let blockedTimeslots = [
      ...this.getBlockedTimesByOpeningHours(openingHours),
      ...this.getBlockedTimesByCurrentTimeOfDay(date),
      ...this.getBlockedTimesByBookedAppointments(blockingAppointmentsOnDay, currentAppointmentId),
    ];
    if (selectedAppointmentType) {
      blockedTimeslots = [...blockedTimeslots, ...this.getBlockedTimesByAppointmentType(selectedAppointmentType, date)];
    }
    if (blockedTimeslots.length === 0) {
      return [{ ...this.blockingTimeslot }];
    }
    const sortedBlockedTimeslots = blockedTimeslots.toSorted((a, b) => (a.from < b.from ? -1 : 1));

    const availableTimeslots: Timeslot[] = [];
    let startTime: Time;
    sortedBlockedTimeslots.forEach((blockedTimeslot, index) => {
      if (index === 0 && blockedTimeslot.from !== this.blockingTimeslot.from) {
        availableTimeslots.push({ from: this.blockingTimeslot.from, until: blockedTimeslot.from });
      }
      if (!startTime) {
        startTime = blockedTimeslot.until;
      } else if (blockedTimeslot.from <= startTime && blockedTimeslot.until > startTime) {
        startTime = blockedTimeslot.until;
      } else if (blockedTimeslot.from > startTime) {
        availableTimeslots.push({ from: startTime, until: blockedTimeslot.from });
        startTime = blockedTimeslot.until;
      }
      if (index === sortedBlockedTimeslots.length - 1 && blockedTimeslot.until !== this.blockingTimeslot.until) {
        availableTimeslots.push({ from: blockedTimeslot.until, until: this.blockingTimeslot.until });
      }
    });

    return availableTimeslots;
  }

  static getSortedOpenedAndClosedTimeslotsFromOpeningHours(openingHours: string): TimeslotWithStatus[] {
    const openedTimeslots = this.getTimeslotsFromOpeningHours(openingHours)
      .filter((timeslot): timeslot is Timeslot => !!timeslot)
      .sort((a, b) => (a.from > b.from ? 1 : -1));
    return openedTimeslots.reduce((acc: TimeslotWithStatus[], currentTimeslot, currentIndex, entireArray) => {
      if (currentIndex === 0 && currentTimeslot.from !== this.blockingTimeslot.from) {
        acc.push({ from: this.blockingTimeslot.from, until: currentTimeslot.from, closed: true });
      }
      acc.push(currentTimeslot);
      if (currentIndex < entireArray.length - 1) {
        const nextTimeslot = entireArray[currentIndex + 1] as Timeslot;
        if (currentTimeslot.until !== nextTimeslot.from) {
          acc.push({ from: currentTimeslot.until, until: nextTimeslot.from, closed: true });
        }
      } else if (currentTimeslot.until !== this.blockingTimeslot.until) {
        acc.push({ from: currentTimeslot.until, until: this.blockingTimeslot.until, closed: true });
      }
      return acc;
    }, []);
  }

  static getAvailableTimeslotTimesForPharmacy(
    timeslot: Timeslot,
    date: Dayjs,
    appointmentType: BookedAppointmentV2Type
  ) {
    const availableDateTimes = this.getAvailableAppointmentTimesInTimeslot(timeslot, date, appointmentType);
    return availableDateTimes.map((dateTime) => `${dateTime.format('HH:mm')} Uhr`);
  }

  static getAvailableTimeslotTimesForEnduser(
    timeslot: Timeslot,
    date: Dayjs,
    appointmentType: BookedAppointmentV2Type
  ) {
    const availableDateTimes = this.getAvailableAppointmentTimesInTimeslot(timeslot, date, appointmentType);
    const filteredDateTimes = this.filterTimes(availableDateTimes);
    return filteredDateTimes.map((dateTime) => dateTime.format('HH:mm'));
  }

  static calculateEndTime(time?: string | null, appointmentType?: BookedAppointmentV2Type | null) {
    if (!time || !appointmentType) {
      return undefined;
    }
    const sanitizedTime = time.split(' ')[0];
    return dayjs
      .tz(sanitizedTime, 'HH:mm', DEFAULT_TIME_ZONE)
      .add(appointmentType.durationInMinutes, 'minutes')
      .format('HH:mm');
  }

  static getArrayIndexFromWeekday(date: Dayjs) {
    return date.isoWeekday() - 1;
  }

  private static getTimeslotsFromOpeningHours(openingHours: string): (Timeslot | null)[] {
    const timeslots = openingHours.split(',');
    return timeslots.map((timeslot) => {
      const timeRegexp = /^(\d{1,2}:\d{1,2})(?::\d{1,2})? -- (\d{1,2}:\d{1,2})(?::\d{1,2})?$/;
      const match = timeslot.trim().match(timeRegexp);
      return match ? ({ from: match[1], until: match[2] } as Timeslot) : null;
    });
  }

  private static getAvailableAppointmentTimesInTimeslot(
    timeslot: Timeslot,
    date: Dayjs,
    appointmentType: BookedAppointmentV2Type
  ) {
    const startTime = timeslot.from.split(':');
    const endTime = timeslot.until.split(':');

    let appointmentTime = date.set('hour', Number(startTime[0])).set('minutes', Number(startTime[1]));
    const timeslotEnd = date.set('hour', Number(endTime[0])).set('minutes', Number(endTime[1]));

    const availableDateTimes: Dayjs[] = [];
    while (appointmentTime.add(appointmentType.durationInMinutes, 'minutes').isSameOrBefore(timeslotEnd)) {
      availableDateTimes.push(appointmentTime);
      appointmentTime = appointmentTime.add(10, 'minutes');
    }
    return availableDateTimes;
  }

  private static filterTimes(times: Dayjs[]): Dayjs[] {
    const timesByHour: { [key: string]: Dayjs[] } = {};
    times.forEach((time) => {
      const hour = time.format('HH');
      if (!timesByHour[hour]) {
        timesByHour[hour] = [];
      }
      timesByHour[hour]?.push(time);
    });
    return Object.keys(timesByHour)
      .sort((a, b) => parseInt(a) - parseInt(b))
      .flatMap((hour) => this.findClosestTimes(timesByHour[hour]));
  }

  private static findClosestTimes(times?: Dayjs[]): Dayjs[] {
    if (!times) {
      return [];
    }
    const result: Dayjs[] = [];
    const sortedTimes = times.toSorted((a, b) => a.minute() - b.minute());
    let closestToZero: Dayjs | undefined = undefined;
    let closestToThirty: Dayjs | undefined = undefined;

    for (const time of sortedTimes) {
      const minute = time.minute();
      if (minute === 0) {
        closestToZero = time;
      } else if (minute === 30) {
        closestToThirty = time;
      } else if (minute < 30 && !closestToZero) {
        closestToZero = time;
      } else if (minute > 30 && !closestToThirty) {
        closestToThirty = time;
      }
    }
    if (closestToZero) {
      result.push(closestToZero);
    }
    if (closestToThirty) {
      result.push(closestToThirty);
    }
    return result;
  }

  private static getBlockedTimesByOpeningHours(openingHours?: string): Timeslot[] {
    if (!openingHours) {
      return [this.blockingTimeslot];
    }
    const timeslots = this.getTimeslotsFromOpeningHours(openingHours);
    if (timeslots.includes(null)) {
      return [this.blockingTimeslot];
    }
    return this.invertTimeslots(timeslots as Timeslot[]);
  }

  private static getBlockedTimesByAppointmentType(
    selectedAppointmentType: AppointmentV2Type,
    selectedDate: Dayjs
  ): Timeslot[] {
    const timeslot = selectedAppointmentType.timeslots[this.getArrayIndexFromWeekday(selectedDate)];
    if (!timeslot) {
      return [this.blockingTimeslot];
    }
    return this.invertTimeslots([timeslot]);
  }

  private static getBlockedTimesByCurrentTimeOfDay(date: Dayjs): Timeslot[] {
    const now = dayjs().tz(DEFAULT_TIME_ZONE);
    if (date.isAfter(now, 'day')) {
      return [];
    }
    if (date.isBefore(now, 'day')) {
      return [this.blockingTimeslot];
    }
    const minuteOffsetToNextInterval = 10 - (now.get('minutes') % 10);
    const until = now.add(minuteOffsetToNextInterval, 'minutes').format('HH:mm') as Time;
    return [{ from: this.blockingTimeslot.from, until }];
  }

  private static getBlockedTimesByBookedAppointments(
    appointmentsOnDay: DecryptedAppointmentV2[] | BlockingAppointmentV2[],
    currentAppointmentId?: string
  ) {
    let bookedAppointments = appointmentsOnDay.filter((appointment) => !appointment.isCancelled);
    if (currentAppointmentId) {
      bookedAppointments = bookedAppointments.filter((appointment) => appointment.id !== currentAppointmentId);
    }
    return bookedAppointments
      .map((appointment) => {
        const regex = /(\d{2}):(\d{2}) Uhr/;
        const match = appointment.time?.match(regex);
        if (!appointment.time || !appointment.appointmentType || !match) {
          return null;
        }
        const endTime = appointment.date
          .set('hour', Number(match[1]))
          .set('minutes', Number(match[2]))
          .add(appointment.appointmentType.durationInMinutes, 'minutes')
          .format('HH:mm');
        return { from: `${match[1]}:${match[2]}`, until: endTime } as Timeslot;
      })
      .filter((timeslot): timeslot is Timeslot => !!timeslot);
  }

  private static invertTimeslots(timeslots: Timeslot[]) {
    if (timeslots.length === 0) {
      return [this.blockingTimeslot];
    }
    const invertedTimeslots: Timeslot[] = [];
    timeslots.forEach((timeslot, index) => {
      if (index === 0 && this.blockingTimeslot.from !== timeslot.from) {
        invertedTimeslots.push({ from: this.blockingTimeslot.from, until: timeslot.from });
      }
      if (timeslot.until < this.blockingTimeslot.until) {
        const from = timeslot.until;
        const until =
          index < timeslots.length - 1
            ? (timeslots[index + 1]?.from ?? this.blockingTimeslot.until)
            : this.blockingTimeslot.until;
        invertedTimeslots.push({ from, until });
      }
    });
    return invertedTimeslots;
  }
}
