<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'APPOINTMENT.FIND' | translate }}</ion-title>
    <ion-buttons slot="end">
      <mea-icon-button (buttonClick)="modalCtrl.dismiss()" color="dark-grey" icon="close"></mea-icon-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

@if (groupedAvailableAppointments$ | async; as groupedAvailableAppointments) {
  <ion-content>
    <ion-accordion-group>
      @for (type of groupedAvailableAppointments; track type.appointmentType.name) {
        <ion-accordion>
          <ion-item slot="header">
            <ion-label class="header"
              >{{ type.appointmentType.name }} (Termine an {{ type.availableDatesAndTimes.length }}
              {{ type.availableDatesAndTimes.length === 1 ? 'Tag' : 'Tagen' }}
              in den nächsten Wochen)</ion-label
            >
          </ion-item>
          <ion-list slot="content">
            <ion-item class="outer-content">
              @if (type.availableDatesAndTimes.length > 0) {
                <div class="inner-accordion-wrapper">
                  <ion-list-header mode="md">
                    <ion-label>Datum</ion-label>
                    <ion-label class="text-align-right">{{ 'APPOINTMENT.OPEN_SLOTS' | translate }}</ion-label>
                  </ion-list-header>
                  @for (day of type.availableDatesAndTimes; track day.date) {
                    <ion-accordion-group>
                      <ion-accordion>
                        <ion-item slot="header">
                          <ion-label class="header">{{ day.date }}</ion-label>
                          <ion-label class="amount" slot="end">{{ day.times.length }}</ion-label>
                        </ion-item>
                        <ion-list slot="content">
                          @for (appointment of day.times; track appointment) {
                            <ion-item
                              (click)="selectAppointment(day.date, appointment, type.appointmentType)"
                              button
                              class="inset"
                            >
                              <ion-label>
                                <h2>
                                  {{ appointment }}
                                  -
                                  {{ calculateEndTime(appointment, type.appointmentType) }}
                                </h2>
                              </ion-label>
                            </ion-item>
                          }
                        </ion-list>
                      </ion-accordion>
                    </ion-accordion-group>
                  }
                </div>
              }
            </ion-item>
          </ion-list>
        </ion-accordion>
      }
      @if (groupedAvailableAppointments.length === 0) {
        <mea-error>
          Leider sind derzeit keine freien Termine verfügbar.<br />Bitte versuchen Sie es später erneut.
        </mea-error>
      }
    </ion-accordion-group>
  </ion-content>
}
