import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { AppointmentV2MappingService } from '../../../common/resources/src/services/appointmentsV2/appointmentV2-mapping.service';
import { AppsyncService, AppsyncServiceClient } from '../../../common/resources/src/services/appsync/appsync.service';
import { Appointment, AppointmentMigrateInput, BackendAppointment } from '../../../essentials/types/src/appointment';
import { BackendAppointmentV2Input, DecryptedAppointmentV2 } from '../../../essentials/types/src/appointmentV2';
import { AppointmentV2Type, BackendAppointmentV2TypeInput } from '../../../essentials/types/src/appointmentV2Type';
import { AppointmentUtil } from '../../../essentials/util/src/appointment.util';
import { AppsyncErrorUtil } from '../../../essentials/util/src/appsync-error.util';
import cancelAppointmentV2 from '../graphql/mutations/appointments/cancelAppointmentV2';
import createAppointmentV2Type from '../graphql/mutations/appointments/createAppointmentV2Type';
import deleteAppointmentV2Type from '../graphql/mutations/appointments/deleteAppointmentV2Type';
import migrateAppointmentsToV2 from '../graphql/mutations/appointments/migrateAppointmentsToV2';
import updateAppointmentV2 from '../graphql/mutations/appointments/updateAppointmentV2';
import updateAppointmentV2Type from '../graphql/mutations/appointments/updateAppointmentV2Type';
import getMyAppointments from '../graphql/queries/getMyAppointments';
import createdOrUpdatedAppointmentV2 from '../graphql/subscriptions/createdOrUpdatedAppointmentV2';
import createdOrUpdatedAppointmentV2Type from '../graphql/subscriptions/createdOrUpdatedAppointmentV2Type';
import deletedAppointmentV2Type from '../graphql/subscriptions/deletedAppointmentV2Type';

@Injectable({ providedIn: 'root' })
export class AppsyncPharmacyAppointmentService {
  private appointmentV2MappingService = inject(AppointmentV2MappingService);
  private appsyncService = inject(AppsyncService);

  // ************* Queries *************

  async getMyAppointments(): Promise<Appointment[]> {
    const client = await this.appsyncService.getClient();

    let next: string | undefined;
    const backendAppointments: BackendAppointment[] = [];
    do {
      const { appointments, nextToken } = await this.getPaginatedAppointments(client, next);
      backendAppointments.push(...appointments);
      next = nextToken;
    } while (next);
    return backendAppointments.map(AppointmentUtil.mapBackendAppointmentToAppointment);
  }

  // ************* Mutations *************

  async createAppointmentV2Type(variables: BackendAppointmentV2TypeInput) {
    const client = await this.appsyncService.getClient();
    await client.mutate({ mutation: createAppointmentV2Type, variables });
  }

  async updateAppointmentV2Type(id: string, backendAppointmentV2Type: BackendAppointmentV2TypeInput) {
    const client = await this.appsyncService.getClient();
    const variables = { ...backendAppointmentV2Type, id };
    await client.mutate({ mutation: updateAppointmentV2Type, variables });
  }

  async deleteAppointmentV2Type(id: string) {
    const client = await this.appsyncService.getClient();
    const variables = { id };
    await client.mutate({ mutation: deleteAppointmentV2Type, variables });
  }

  async cancelAppointmentV2(id: string) {
    const client = await this.appsyncService.getClient();
    const appointmentData = await client.mutate({
      mutation: cancelAppointmentV2,
      variables: { id, isCancelled: true },
    });
    const backendAppointment = appointmentData.data.updateAppointmentV2;
    return this.appointmentV2MappingService.mapBackendAppointmentV2ToDecryptedAppointmentV2(backendAppointment);
  }

  async updateAppointmentV2(id: string, variables: BackendAppointmentV2Input) {
    const client = await this.appsyncService.getClient();
    const appointmentData = await client.mutate({
      mutation: updateAppointmentV2,
      variables: { id, ...variables },
    });
    const backendAppointment = appointmentData.data.updateAppointmentV2;
    return this.appointmentV2MappingService.mapBackendAppointmentV2ToDecryptedAppointmentV2(backendAppointment);
  }

  async migrateAppointmentsToV2(appointments: AppointmentMigrateInput[]) {
    const client = await this.appsyncService.getClient();
    await client.mutate({
      mutation: migrateAppointmentsToV2,
      variables: { appointments },
    });
  }

  // ************* Subscriptions *************

  createdOrUpdatedAppointmentV2Type(pharmacyCognitoId: string): Observable<AppointmentV2Type> {
    return from(this.appsyncService.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: createdOrUpdatedAppointmentV2Type,
            variables: { pharmacyCognitoId },
          })
          .pipe(
            map((response) =>
              AppointmentUtil.mapBackendAppointmentV2TypeToAppointmentV2Type(
                response.data.createdOrUpdatedAppointmentV2Type
              )
            )
          )
      )
    );
  }

  deletedAppointmentV2Type(pharmacyCognitoId: string): Observable<AppointmentV2Type> {
    return from(this.appsyncService.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: deletedAppointmentV2Type,
            variables: { pharmacyCognitoId },
          })
          .pipe(
            map((response) =>
              AppointmentUtil.mapBackendAppointmentV2TypeToAppointmentV2Type(response.data.deletedAppointmentV2Type)
            )
          )
      )
    );
  }

  createdOrUpdatedAppointmentV2(pharmacyCognitoId: string): Observable<DecryptedAppointmentV2> {
    return from(this.appsyncService.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: createdOrUpdatedAppointmentV2,
            variables: { pharmacyCognitoId },
          })
          .pipe(
            switchMap(async (response) =>
              this.appointmentV2MappingService.mapBackendAppointmentV2ToDecryptedAppointmentV2(
                response.data.createdOrUpdatedAppointmentV2
              )
            ),
            filter((appointment): appointment is DecryptedAppointmentV2 => !!appointment)
          )
      )
    );
  }

  // ************* Helpers *************

  private getPaginatedAppointments = async (
    client: AppsyncServiceClient,
    nextToken: string | undefined
  ): Promise<{ appointments: BackendAppointment[]; nextToken: string | undefined }> => {
    let data;
    try {
      data = (
        await client.query({
          query: getMyAppointments,
          variables: { nextToken },
        })
      ).data;
      return data.getMyAppointments;
    } catch (err) {
      if (
        AppsyncErrorUtil.isAppsyncError(err) &&
        err.errors.every((error) => AppsyncErrorUtil.isElasticSearchNotFoundError(error)) &&
        err.data
      ) {
        return (err.data as any).getMyAppointments;
      } else {
        throw err;
      }
    }
  };
}
