import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Subject } from 'rxjs';
import { ChatUser, isPharmacyChatUser } from '../../../../essentials/types/src/chatUser';
import { LogoutType } from '../../../../essentials/types/src/logoutType';
import { CONFIG } from '../../../../essentials/types/src/mea-config';
import { Logger } from '../../../../essentials/util/src/logger';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { navigateToLoginPage } from '../../../../store/src/common-store/other/actions/navigation.actions';
import {
  clearUserOnLogout,
  initUser,
  resetUserState,
} from '../../../../store/src/common-store/user-store/actions/user.actions';
import { selectUser } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import { StatePersistenceService } from '../common-store/persistence/state-persistence.service';
import { CustomAmplifyService } from './amplify.service';
import { PrivateKeyStoreService } from './encryption/private-key-store.service';
import { LogoutNotificationService } from './logout-notification.service';
import { MessageStoreService } from './message-cache/message-store.service';
import { StorageService } from './storage.service';

const logger = new Logger('LogoutService');

@Injectable({ providedIn: 'root' })
export class LogoutService {
  private config = inject(CONFIG);
  private amplifyService = inject(CustomAmplifyService);
  private logoutNotificationService = inject(LogoutNotificationService);
  private messageStoreService = inject(MessageStoreService);
  private privateKeyStoreService = inject(PrivateKeyStoreService);
  private statePersistenceService = inject(StatePersistenceService);
  private storage = inject(StorageService);
  private store: Store<CommonState> = inject(Store);

  showToastOnLogout = this.config.featureFlags.showToastOnEndUserLogout;
  logoutWithoutRedirection$ = new Subject<LogoutType>();
  private readonly isPharmacy = !this.config.clientApp;

  public async logout(logoutType: LogoutType): Promise<void> {
    logger.info('signOut user:', logoutType);
    try {
      if (this.config.featureFlags.logoutOnUserError) {
        await this.amplifyService.auth().signOut();
        logger.debug('signOut success');
        await this.cleanupUserStorage(logoutType);
      }
      this.amplifyService.logoutEvent$.next(logoutType);
      if (this.isPharmacy) {
        if (logoutType !== LogoutType.ActiveSconnectLogout) {
          this.store.dispatch(navigateToLoginPage());
        }
        if (logoutType === LogoutType.SsoSessionExpired) {
          await this.logoutNotificationService.showLogoutToast(logoutType);
        }
      } else {
        if (this.shouldRedirect(logoutType)) {
          this.store.dispatch(navigateToLoginPage());
        } else {
          this.logoutWithoutRedirection$.next(logoutType);
        }
        if (this.showToastOnLogout) {
          await this.logoutNotificationService.showLogoutToast(logoutType);
        }
      }
    } catch (err) {
      logger.error('signOut error', err);
      throw err;
    }
  }

  public async cleanupUserStorage(logoutType: LogoutType) {
    const currentUser = await firstValueFrom(this.store.select(selectUser));
    const currentCognitoId = currentUser?.cognitoId;

    this.store.dispatch(clearUserOnLogout());
    await this.deleteSsoLoginStorage(currentUser);

    switch (logoutType) {
      case LogoutType.ActiveDeleted:
      case LogoutType.Expired:
      case LogoutType.SilentExpired:
      case LogoutType.BackendDeleted:
        this.deleteLocalStorageOfUser(currentCognitoId);
        await this.deleteChatDataOfUser(currentCognitoId);
        await this.privateKeyStoreService.removePrivateKey(currentCognitoId);
        if (currentUser && currentUser.registered) {
          this.loadGuestUserIfExists();
        } else {
          await this.deleteCognitoData();
        }
        break;
      case LogoutType.NoPrivateKey:
        if (currentUser && currentUser.isGuest) {
          this.deleteLocalStorageOfUser(currentCognitoId);
        } else {
          this.loadGuestUserIfExists();
        }
        break;
      case LogoutType.ActiveLogout:
        if (!this.isPharmacy) {
          await this.deleteChatDataOfUser(currentCognitoId);
          this.loadGuestUserIfExists();
        }
        break;
      case LogoutType.AppDataDeleted:
        if (this.isPharmacy) {
          this.deleteLocalStorageOfUser(currentCognitoId);
          await this.deleteChatDataOfUser(currentCognitoId);
        }
        break;
      case LogoutType.InvalidPrivateKeyOnInitialLoadForGuest:
      case LogoutType.FailedInitialLoad:
        await this.deleteCognitoData();
        break;
      case LogoutType.InvalidPrivateKeyOnInitialLoadForNonGuestUser:
        this.loadGuestUserIfExists();
        break;
      case LogoutType.ActiveSconnectLogout:
      case LogoutType.BackendDisabledPharmacy:
      case LogoutType.InconsistentWithBackend:
      case LogoutType.SignInFailed:
      case LogoutType.SsoSessionExpired:
        break;
    }
  }

  async deleteChatDataOfUser(cognitoId?: string): Promise<void> {
    if (cognitoId) {
      await this.messageStoreService.deleteCache(cognitoId);
      await this.statePersistenceService.removePersistedStatesForUser(cognitoId);
    }
  }

  public deleteLocalStorageOfUser(cognitoId?: string): void {
    if (cognitoId) {
      for (const key of Object.keys(localStorage)) {
        if (key.includes(cognitoId)) {
          localStorage.removeItem(key);
        } else if (
          (key.startsWith('CognitoIdentityId') || key.startsWith('aws')) &&
          localStorage.getItem(key) === cognitoId
        ) {
          localStorage.removeItem(key);
        }
      }
    }
  }

  deleteCognitoData = async (): Promise<void> => {
    for (const key of Object.keys(localStorage)) {
      if (key.includes('aws') || key.includes('CognitoIdentityId')) {
        localStorage.removeItem(key);
      }
    }
    await this.storage.forEach((_, key: string) => {
      if (key.includes('aws') || key.includes('CognitoIdentityId')) {
        this.storage.remove(key);
      }
    });
  };

  private loadGuestUserIfExists() {
    this.store.dispatch(resetUserState());
    this.store.dispatch(initUser());
  }

  private async deleteSsoLoginStorage(currentUser: ChatUser | null) {
    if (currentUser && currentUser.cognitoUsername && isPharmacyChatUser(currentUser)) {
      await this.storage.remove(`${currentUser.cognitoUsername}_loggedInWithSso`);
    }
  }

  private shouldRedirect(logoutType: LogoutType): boolean {
    return (
      !this.config.baseUrl &&
      ![LogoutType.SignInFailed, LogoutType.Expired, LogoutType.SilentExpired].includes(logoutType)
    );
  }
}
