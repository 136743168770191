import gql from 'graphql-tag';
import { pharmacyAttributes } from '../attributes';

export default gql`
  query getPharmacy($id: ID!) {
    getPharmacy(id: $id) {
      ${pharmacyAttributes}
      pharmacyChatUser {
        __typename
        pharmacyId
        cognitoId
        cognitoUsername
        publicKey
        userType
        userStatus
        addons {
          __typename
          type
          status
          subscriptionTimestamp
          expirationTimestamp
        }
        initialMessages {
          askQuestion
          askQuestionClosed
          preorder
          preorderClosed
          productQuery
          productQueryClosed
        }
        holidays {
            state
            customHolidays
        }
        vacation {
            from
            until
        }
        emergencyOpeningStatus
      }
    }
  }
`;
