import gql from 'graphql-tag';
import { appointmentV2TypeAttributes } from '../../../../../common/resources/src/graphql/attributes';

export default gql`
  mutation updateAppointmentV2Type(
    $id: ID!
    $name: String!
    $durationInMinutes: Int!
    $attachments: [AppointmentAttachmentInput!]!
    $color: String!
    $mandatoryCustomerFields: [String!]!
    $additionalInformation: String
    $automaticMessage: String!
    $leadTimeInHours: Int!
    $timeslots: [TimeslotInput]!
    $startDate: AWSDate
    $endDate: AWSDate
    $isActive: Boolean
    $isImportedAppointmentType: Boolean
  ) {
    updateAppointmentV2Type(
      id: $id
      appointmentV2Type: {
        name: $name
        durationInMinutes: $durationInMinutes
        attachments: $attachments
        color: $color
        mandatoryCustomerFields: $mandatoryCustomerFields
        additionalInformation: $additionalInformation
        automaticMessage: $automaticMessage
        leadTimeInHours: $leadTimeInHours
        timeslots: $timeslots
        startDate: $startDate
        endDate: $endDate
        isActive: $isActive
        isImportedAppointmentType: $isImportedAppointmentType
      }
    ) {
      ${appointmentV2TypeAttributes}
    }
  }
`;
