import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_TIME_ZONE } from '../../../common/resources/src/global-variables';
import { Appointment, BackendAppointment } from '../../types/src/appointment';
import { DecryptedAppointmentV2 } from '../../types/src/appointmentV2';
import {
  AppointmentV2Attachment,
  AppointmentV2Type,
  BackendAppointmentV2Type,
  BookedAppointmentV2Type,
  MandatoryCustomerFields,
} from '../../types/src/appointmentV2Type';

dayjs.extend(utc);
dayjs.extend(timezone);

export class AppointmentUtil {
  public static mapBackendAppointmentV2TypeToAppointmentV2Type(
    backendAppointmentType: BackendAppointmentV2Type
  ): AppointmentV2Type {
    const mandatoryCustomerFields = new Set<MandatoryCustomerFields>([
      ...backendAppointmentType.mandatoryCustomerFields,
    ] as MandatoryCustomerFields[]);
    const attachments = new Set<AppointmentV2Attachment>([...backendAppointmentType.attachments]);
    const startDate = backendAppointmentType.startDate
      ? dayjs.tz(backendAppointmentType.startDate, DEFAULT_TIME_ZONE)
      : null;
    const endDate = backendAppointmentType.endDate ? dayjs.tz(backendAppointmentType.endDate, DEFAULT_TIME_ZONE) : null;
    return { ...backendAppointmentType, attachments, mandatoryCustomerFields, startDate, endDate };
  }

  public static mapAppointmentV1ToV2ForDisplay(appointmentV1?: Appointment): DecryptedAppointmentV2 | undefined {
    if (!appointmentV1) {
      return undefined;
    }
    const oldName = appointmentV1.selectedAppointmentType;
    const date = dayjs(appointmentV1.dateTime).tz(DEFAULT_TIME_ZONE);
    const appointmentType: BookedAppointmentV2Type = {
      attachments: new Set(),
      durationInMinutes: appointmentV1.durationMinutes,
      id: 'migratedAppointmentV1TypeId',
      mandatoryCustomerFields: new Set<MandatoryCustomerFields>(['Name']),
      name: oldName?.substring(oldName?.indexOf('_') + 1) ?? 'Migrierter Termin',
    };
    return {
      id: 'migratedAppointmentV1Id',
      appointmentType,
      pharmacyCognitoId: appointmentV1.pharmacyCognitoId,
      customerName: { decryptionSuccess: true, value: appointmentV1.bookedByName ?? 'App-Nutzer' },
      date,
      time: `${date.format('HH:mm')} Uhr`,
      customerEmail: appointmentV1.bookedByEmail,
    };
  }

  public static mapBackendAppointmentToAppointment(backendAppointment: BackendAppointment): Appointment {
    const availableAppointmentTypes =
      backendAppointment.availableAppointmentTypes && new Set(backendAppointment.availableAppointmentTypes);
    return {
      ...backendAppointment,
      availableAppointmentTypes,
    };
  }

  public static getCurrentAppointmentMonth(day: Dayjs) {
    return day.format('YYYY-MM');
  }

  public static getAppointmentMonthsForMonth(day: Dayjs) {
    const currentMonth = day.format('YYYY-MM');
    const futureMonthToLoad = day.add(1, 'month').format('YYYY-MM');
    const pastMonthToLoad = day.subtract(1, 'month').format('YYYY-MM');
    return [currentMonth, futureMonthToLoad, pastMonthToLoad];
  }
}
