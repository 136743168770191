import { Inject, inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AppointmentV2Input,
  BackendAppointmentV2,
  BlockingAppointmentV2,
  DecryptedAppointmentV2,
  DecryptedCustomerField,
  EncryptedCustomerField,
  MandatoryAppointmentFields,
  Sex,
} from '../../../../../essentials/types/src/appointmentV2';
import {
  BookedAppointmentV2Type,
  MandatoryCustomerFields,
} from '../../../../../essentials/types/src/appointmentV2Type';
import { CONFIG, MeaConfig } from '../../../../../essentials/types/src/mea-config';
import { Logger } from '../../../../../essentials/util/src/logger';
import { isNotNullOrUndefined } from '../../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { selectUser } from '../../../../../store/src/common-store/user-store/selectors/user.selectors';
import { DEFAULT_TIME_ZONE } from '../../global-variables';
import { EncryptionService } from '../encryption/encryption.service';

dayjs.extend(utc);
dayjs.extend(timezone);

const logger = new Logger('AppointmentV2MappingService');

@Injectable({ providedIn: 'root' })
export class AppointmentV2MappingService {
  private encryptionService = inject(EncryptionService);
  private store: Store<CommonState> = inject(Store);

  isPharmacy = !this.config.clientApp;
  privateKey$ = this.store.select(selectUser).pipe(
    isNotNullOrUndefined(),
    map((user) => user.privateKey)
  );

  constructor(@Inject(CONFIG) private config: MeaConfig) {}

  async mapBackendAppointmentV2ToDecryptedAppointmentV2(backendAppointment: BackendAppointmentV2) {
    const decryptedMandatoryFields = await this.decryptMandatoryFields(backendAppointment.mandatoryCustomerFields);
    const date = dayjs(backendAppointment.dateTime).tz(DEFAULT_TIME_ZONE);
    const appointment: DecryptedAppointmentV2 = {
      id: backendAppointment.id,
      pharmacyCognitoId: backendAppointment.pharmacyCognitoId,
      appointmentType: this.getAppointmentType(backendAppointment),
      customerPhone: backendAppointment.telephone,
      customerEmail: backendAppointment.email,
      date,
      time: `${date.format('HH:mm')} Uhr`,
      isCancelled: backendAppointment.isCancelled,
      enduserCognitoId: backendAppointment.enduserCognitoId,
      conversationId: backendAppointment.conversationId,
      ...decryptedMandatoryFields,
    };
    return appointment;
  }

  mapDecryptedAppointmentV2ToAppointmentV2Input(appointment: DecryptedAppointmentV2): AppointmentV2Input {
    return {
      ...appointment,
      customerName: appointment.customerName.value || '',
      customerAge: appointment.customerAge?.value,
      customerSex: appointment.customerSex?.value,
    };
  }

  mapBlockingBackendAppointmentV2ToBlockingAppointmentV2(
    backendAppointment: BackendAppointmentV2 & { isCancelled?: false }
  ) {
    const date = dayjs(backendAppointment.dateTime).tz(DEFAULT_TIME_ZONE);
    const appointment: BlockingAppointmentV2 = {
      id: backendAppointment.id,
      appointmentType: this.getAppointmentType(backendAppointment),
      date,
      time: `${date.format('HH:mm')} Uhr`,
      isCancelled: backendAppointment.isCancelled,
    };
    return appointment;
  }

  private async decryptMandatoryFields(
    encryptedMandatoryFields: MandatoryAppointmentFields
  ): Promise<Pick<DecryptedAppointmentV2, 'customerName' | 'customerAge' | 'customerSex'>> {
    const privateKey = await firstValueFrom(this.privateKey$);
    if (!privateKey) {
      logger.error('error decrypting mandatory fields: no private key found');
    }
    const customerName = this.decryptCustomerName(encryptedMandatoryFields.name, privateKey);
    const customerAge = this.decryptCustomerAge(encryptedMandatoryFields.age, privateKey);
    const customerSex = this.decryptCustomerSex(encryptedMandatoryFields.sex, privateKey);
    return { customerName, customerAge, customerSex };
  }

  private decryptCustomerName(encryptedCustomerField: EncryptedCustomerField, privateKey?: string) {
    const encryptedName = this.isPharmacy ? encryptedCustomerField.pharmacy : encryptedCustomerField.enduser;
    const decryptedCustomerName =
      privateKey && encryptedName && this.encryptionService.decryptUsingPrivateKey(encryptedName, privateKey);
    const customerName: DecryptedCustomerField<string> = decryptedCustomerName
      ? { decryptionSuccess: true, value: decryptedCustomerName }
      : { decryptionSuccess: false };
    if (!customerName.decryptionSuccess) {
      logger.error('error decrypting mandatory fields: could not decrypt customerName');
    }
    return customerName;
  }

  private decryptCustomerAge(encryptedCustomerField?: EncryptedCustomerField, privateKey?: string) {
    let customerAge: DecryptedCustomerField<number> | undefined;
    const encryptedAge = this.isPharmacy ? encryptedCustomerField?.pharmacy : encryptedCustomerField?.enduser;
    if (encryptedAge) {
      const decryptedCustomerAge =
        privateKey && this.encryptionService.decryptUsingPrivateKey(encryptedAge, privateKey);
      customerAge = decryptedCustomerAge
        ? { decryptionSuccess: true, value: Number(decryptedCustomerAge) }
        : { decryptionSuccess: false };
      if (!customerAge.decryptionSuccess) {
        logger.error('error decrypting mandatory fields: could not decrypt customerAge');
      }
    }
    return customerAge;
  }

  private decryptCustomerSex(encryptedCustomerField?: EncryptedCustomerField, privateKey?: string) {
    let customerSex: DecryptedCustomerField<Sex> | undefined;
    const encryptedSex = this.isPharmacy ? encryptedCustomerField?.pharmacy : encryptedCustomerField?.enduser;
    if (encryptedSex) {
      const decryptedCustomerSex =
        privateKey && this.encryptionService.decryptUsingPrivateKey(encryptedSex, privateKey);
      customerSex = decryptedCustomerSex
        ? { decryptionSuccess: true, value: decryptedCustomerSex as Sex }
        : { decryptionSuccess: false };
      if (!customerSex.decryptionSuccess) {
        logger.error('error decrypting mandatory fields: could not decrypt customerSex');
      }
    }
    return customerSex;
  }

  private getAppointmentType(backendAppointment: BackendAppointmentV2): BookedAppointmentV2Type {
    const mandatoryCustomerFields = new Set<MandatoryCustomerFields>(['Name']);
    if (backendAppointment.mandatoryCustomerFields?.age) {
      mandatoryCustomerFields.add('Alter');
    }
    if (backendAppointment.mandatoryCustomerFields?.sex) {
      mandatoryCustomerFields.add('Geschlecht');
    }
    return {
      id: backendAppointment.appointmentTypeId,
      name: backendAppointment.name,
      durationInMinutes: backendAppointment.durationInMinutes,
      mandatoryCustomerFields,
      attachments: new Set(backendAppointment.attachments),
      additionalInformation: backendAppointment.additionalInformation,
    };
  }
}
