import { Injectable } from '@angular/core';
import createPharmacyChatUser from '../../../common/resources/src/graphql/mutations/createPharmacyChatUser';
import resendInitialPassword from '../../../common/resources/src/graphql/mutations/resendInitialPassword';
import updatePharmacyChatUserEncryptedPrivateKey from '../../../common/resources/src/graphql/mutations/updatePharmacyChatUserEncryptedPrivateKey';
import updatePharmacyCognitoId from '../../../common/resources/src/graphql/mutations/updatePharmacyCognitoId';
import updatePharmacyInitialMessages from '../../../common/resources/src/graphql/mutations/updatePharmacyInitialMessages';
import { AppsyncService } from '../../../common/resources/src/services/appsync/appsync.service';
import { EncryptionResult } from '../../../common/resources/src/services/encryption/private-key-backup.service';
import { ChatUser } from '../../../essentials/types/src/chatUser';
import { InitialMessages } from '../../../essentials/types/src/initialMessages';
import { PharmacyChatUserCreateInput } from '../../../essentials/types/src/pharmacyChatUserCreateInput';
import { Logger } from '../../../essentials/util/src/logger';

const logger = new Logger('AppsyncPharmacyService');

@Injectable({
  providedIn: 'root',
})
export class AppsyncPharmacyService {
  constructor(private appsync: AppsyncService) {}

  // ************* Mutations *************

  async createPharmacyChatUser(user: PharmacyChatUserCreateInput): Promise<ChatUser> {
    const client = await this.appsync.getClient();
    const { data } = await client.mutate({
      mutation: createPharmacyChatUser,
      variables: user,
    });

    return data.createPharmacyChatUser;
  }

  async updatePharmacyCognitoId(pharmacyId: string, cognitoId: string): Promise<void> {
    const client = await this.appsync.getClient();
    await client.mutate({
      mutation: updatePharmacyCognitoId,
      variables: {
        pharmacyId,
        cognitoId,
      },
    });
  }

  async updatePharmacyEncryptedPrivateKey(cognitoId: string, encryptionResult: EncryptionResult): Promise<void> {
    const client = await this.appsync.getClient();
    await client.mutate({
      mutation: updatePharmacyChatUserEncryptedPrivateKey,
      variables: {
        cognitoId,
        encryptedPrivateKey: encryptionResult.encryptedPrivateKey,
        encryptionSalt: encryptionResult.salt,
      },
    });
  }

  async updatePharmacyInitialMessages(cognitoId: string, initialMessages: InitialMessages) {
    const client = await this.appsync.getClient();
    await client.mutate({
      mutation: updatePharmacyInitialMessages,
      variables: { cognitoId, initialMessages },
    });
  }

  async resendInitialPharmacyPassword(username: string): Promise<boolean> {
    const client = await this.appsync.getClient();
    const { data } = await client.mutate({
      mutation: resendInitialPassword,
      variables: { sanacorpCustomerId: username.toLowerCase().trim() },
    });
    if (data.resendTemporaryPasswordForPharmacy) {
      return true;
    } else {
      logger.error('Failed to resend initial password, lambda returned false');
      return false;
    }
  }
}
